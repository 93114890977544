<template>
  <div>
    <Breadcrumbs :main="$tc('models.admin.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';

export default {
  name: 'AdminEdit',
  data() {
    return {
      modelName: 'Admin',
      fields: {
        name: {
          type: 'text',
          key: 'name',
          value: '',
          label: this.$t('models.admin.attributes.name'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.admin.attributes.email'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        password: {
          type: 'password',
          key: 'password',
          value: '',
          label: this.$t('models.admin.attributes.password'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        password_confirmation: {
          type: 'password',
          key: 'password_confirmation',
          value: '',
          label: this.$t('models.admin.attributes.password_confirmation'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      record: 'admin'
    })
  },
  methods: {
    ...mapActions({
      getRecord: 'getAdmin',
      updateRecord: 'updateAdmin'
    }),
    prepareForm() {
      let currentRecord = this.$route.params.id;
      if (currentRecord) {
        this.isEditMode = true;
        const currentUser =  this.$store.getters.currentUser;
        if (currentUser.role !== 'super' && currentRecord !== currentUser.id) {
          currentRecord = currentUser.id;
        }
        this.getRecord({id: currentRecord}).then(() => {
          this._.forEach(this.fields, (field) => {
            if (field.type === 'file') {
              this.fields[field.key].origFile = this.record[field.key];
            } else {
              this.fields[field.key].value = this.record[field.key];
            }
            if (this.isEditMode) {
              this.fields[field.key].valid = true;
            }
          });
        });
      }
    }
  },
  mixins: [FormsMixin]
};
</script>
